<template>
  <div>
    <p><strong>Ваш аккаунт деактивирован.</strong></p>
    <p>Для активации, свяжитесь с нами:</p>
    <p>ibzamyatin@gmail.com</p>
    <p>Telegram: @ZamyatinIvan</p>
    <p>Whatsapp: +7 (911) 166-52-56</p>
  </div>
</template>
<script>

export default {
  name: 'Blocked'
}
</script>

<style lang="scss">
body {
  padding: 20px;
  text-align: center;
}
</style>
